/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

nav {
	background-color: #4CA193;
	/* background-color: #91BE6C; */
	height: 15vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav-items {
display: flex;
flex-direction: row;
align-items: center;
}

.nav-left-items {
display: flex;
flex-direction: row;
align-items: center;
width: 50vw;
font-size: 1.2rem;
}

.home-pprLogo {
height: 12vh;
width: auto;
margin-left: 1rem;
}
.nav-left-info {
padding-left: 1rem;
display: flex;
flex-direction: column;
text-align: left;
}

.nav-right-item {
display: flex;
width: 48vw;
justify-content: flex-end;
font-size: 1.2rem;
}

.nav-right-item > * {
text-decoration: none;
color: black;
padding-right: 4rem;
font-size: 1.5rem;
}

.nav-right-item > *:hover {
color: #386FA2;
}

.navbar_notice {
	height: 8vh;
	font-size: 50px;
	background-color: yellow;
	display: flex;
	align-items: center;
	justify-content: center;
}